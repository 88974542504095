.scroll-x {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: auto;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: row;
}

.scroll-x ion-chip {
    flex: 0 0 auto;
    width: auto;
}

.clear-button {
    padding-left: 8px;
    padding-right: 8px;
}

.clear-button ion-icon {
    margin: 0;
}

.game-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    padding: 16px;
    max-width: 1200px;
    margin: auto;
}

@media screen and (min-width: 600px) {
    .game-cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 970px) {
    .game-cards {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 1200px) {
    .game-cards {
        grid-template-columns: repeat(6, 1fr);
    }
}

.game-cards ion-item ion-thumbnail {
    width: 100%;
    height: auto;
    clear: both;
    margin: 0;
    aspect-ratio: 1;
}

.game-cards ion-label {
    margin-top: 0;
}

.game-cards ion-item::part(native) {
    display: block;
    padding: 0;
}