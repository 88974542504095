@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

.ios {
    ion-toolbar>ion-searchbar {
        padding-bottom: 0;
    }
}

.list-md {
    background: transparent;
}

.sprite {
    height: 64px;
    image-rendering: pixelated;
    clear: both;
}

.sprite.flip {
    transform: scaleX(-1);
}


/* Utilities */
.mr-0 {
    margin-right: 0;
}

.mr-1 {
    margin-right: 4px;
}

.mb-1 {
    margin-bottom: 4px;
}

.mt-1 {
    margin-top: 4px;
}

.pl-3 {
    padding-left: 12px;
}