.no-thumbnail {
    background: rgba(var(--ion-color-danger-rgb), .25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-thumbnail ion-icon {
    width: 68%;
    height: 68%;
    opacity: .25;
}